import React from 'react'
import styled from 'styled-components'

const TextBlock = ( { title, subTitle, children, style } ) => {
    const Title = styled.h2`
        font-family: 'Lato';
        font-size: 24px;
        font-weight: 900;
        line-height: 32px;
        color: #323232;
        text-align: left;
        margin: 0;
    `;

    const SubTitle = styled.h3`
        font-family: 'Source Serif Pro';
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #666;
        text-align: left;
        margin: 8px 0px 0px 0px
    `

    const Text = styled.div`
        font-family: 'Source Serif Pro';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #323232;
        text-align: left;
        margin: 16px 0px 0px 0px;
    `

    return (
        <>  
            <Title style={style}>{ title} </Title>
            { subTitle && <SubTitle>{ subTitle }</SubTitle> }
            { children && <Text>{ children }</Text> }
        </>
    )
}

export default TextBlock