import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const Hero = ( { file, style } ) => {
    const Hero = styled(Img)`
        width: 100%;
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
        position: relative;
        z-index: 5
    `;
    return (
        <Hero fluid={file} style={style}/>
    )
}

export default Hero